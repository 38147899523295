<template>
  <div class="d-flex flex-column">
    
    <div v-if="order && order.status === 'WAIT_PAYMENT' && order.cancel_in_seconds !== null && cancel_in_seconds > 0">
      <p style="text-align: center; margin-top: 4px;">
        {{$t('SHOP_ORDER.CANCEL_IN_SECONDS', { seconds: cancel_in_seconds })}}
      </p>
    </div>

    <div v-if="order && (order.status === 'CANCELLED' || ( order.status === 'WAIT_PAYMENT' && cancel_in_seconds < 0))">
      
      
      <b-card style="min-width: 400px;" class="mt-4 mb-16">
        <div class="col-12 text-center">
          <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
        </div>

        <div class="d-flex justify-content-center mb-8 mt-8">
          <span class="text-center" style="font-weight: bold;">{{ $t('PUBLIC.PAYMENT.PAYMENT_TIMEOUT') }}</span>
        </div>

        <div class="d-flex justify-content-center mb-8 mt-8">
          <span class="text-center">{{ $t('PUBLIC.PAYMENT.START_OVER') }}</span>
        </div>
    
      </b-card>
    </div>

    <div v-else>
      
      <PaymentStatusComponent
        v-if="order && show_payment_status"
        :order="order"
        @done="payment_done"
      />

      <b-card v-if="selected_payment_method === null" class="mt-4 mb-4" style="min-width: 400px;">

        <OrderSummaryComponent
          v-if="order"
          :is_donation="is_donation"
          :order="order"
        />

      </b-card>

      <div v-if="error">        
        <div v-if="error === 'invalid_shop_order_id'">
          {{ $t('PUBLIC.PAYMENT.INVALID_SHOP_ORDER_ID') }}
        </div>
        <div v-if="error === 'invalid_token'">
          {{ $t('PUBLIC.PAYMENT.INVALID_TOKEN') }}
        </div>
        <div v-if="error === 'swish exception'">
          {{ $t('PUBLIC.PAYMENT.SWISH_EXCEPTION') }}
        </div>
      </div>

      <SelectPaymentComponent 
        v-if="!payment_method && !payment_complete && order && !payment_failed"
        @selected="payment_method_selected"
        :order="order"
      />

      <SwishPaymentComponent
        style="max-width: 482px; margin: auto;"
        id="scroll-to-element" 
        v-if="order && !order.is_subscription && selected_payment_method === 'SWISH' && !payment_complete && !payment_failed"
        :order="order"
        @updated="order_updated"
        @start_polling="start_polling_from_child_component"
      />

      <!--<AutogiroPaymentComponent
        style="max-width: 482px; margin: auto;"
        id="scroll-to-element" 
        v-if="order && order.is_subscription && selected_payment_method === 'AUTOGIRO' && !payment_complete && !payment_failed"
        :order="order"
        :personal_details="personal_details"
        @updated="order_updated"
        @start_polling="start_polling_from_child_component"
      />-->

      <SwishSubscriptionPaymentComponent
        style="max-width: 482px; margin: auto;"
        id="scroll-to-element" 
        v-if="order && order.is_subscription && selected_payment_method === 'SWISH' && !payment_complete && !payment_failed"
        :order="order"
        :personal_details="local_personal_details"
        @updated="order_updated"
        @start_polling="start_polling_from_child_component"
      />

      <StripePaymentComponent
        style="max-width: 482px; margin: auto;"
        id="scroll-to-element"
        class="mt-16" 
        v-if="order && !order.is_subscription && selected_payment_method === 'STRIPE' && !payment_complete && !payment_failed"
        :order="order"
        @updated="order_updated"
        @start_polling="start_polling_from_child_component"
      />

      <StripeSubscriptionPaymentComponent 
        style="max-width: 482px; margin: auto;"
        id="scroll-to-element" 
        class="mt-16"
        v-if="order && order.is_subscription && selected_payment_method === 'STRIPE' && !payment_complete && !payment_failed"
        :order="order"
        @updated="order_updated"
        @start_polling="start_polling_from_child_component"
      />

      <InvoicePaymentComponent 
        style="max-width: 482px; margin: auto;"
        id="scroll-to-element"
        class="mt-16"
        v-if="selected_payment_method === 'INVOICE' && !payment_complete && !payment_failed"
        :order="order"
        :personal_details="local_personal_details"
        
        @done="invoice_done"
      />

      <p style="margin-top: 128px;"></p>

    </div>

  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';
import SelectPaymentComponent from '@/view/pages/members/payment/SelectPaymentComponent.vue';
import StripeSubscriptionPaymentComponent from '@/view/pages/members/payment/StripeSubscriptionPaymentComponent.vue';
import SwishSubscriptionPaymentComponent from '@/view/pages/members/payment/SwishSubscriptionPaymentComponent.vue';
import SwishPaymentComponent from '@/view/pages/members/payment/SwishPaymentComponent.vue';
import StripePaymentComponent from '@/view/pages/members/payment/StripePaymentComponent.vue';
//import AutogiroPaymentComponent from '@/view/pages/members/payment/AutogiroPaymentComponent.vue';
import OrderSummaryComponent from '@/view/pages/members/payment/OrderSummaryComponent.vue';
import InvoicePaymentComponent from '@/view/pages/members/payment/InvoicePaymentComponent.vue';
import PaymentStatusComponent from '@/view/pages/members/payment/PaymentStatusComponent.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';


export default {
  name: 'SelectPaymentPage',
  props: ['prop_shop_order','is_donation','payment_method','personal_details'],
  mixins: [ toasts ],
  components: {
    SwishPaymentComponent,
    SwishSubscriptionPaymentComponent,
    //AutogiroPaymentComponent,
    SelectPaymentComponent,
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo,
    OrderSummaryComponent,
    StripeSubscriptionPaymentComponent,
    StripePaymentComponent,
    InvoicePaymentComponent,
    PaymentStatusComponent
  },
  computed: {
    payment_complete() {
      return this.order && this.order.status === 'PAID_IN_FULL';
    },

    payment_failed() {
      return this.order && this.order.status === 'FAILED';
    },

    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },
    
  },
  data() {
    return {
      show_payment_status: false,
      cancel_in_seconds: 0,
      selected_payment_method: null,
      shop_order_id: null,
      token: null,
      error: null,
      order: null,
      email: null,
      local_personal_details: null
    };
  },
  async mounted() {

    if (this.prop_shop_order) {
      this.shop_order_id = this.prop_shop_order.shop_order_id;
      this.token = this.prop_shop_order.token;
    }
    else {
      this.shop_order_id = this.$route.params.shop_order_id;
      this.token = this.$route.params.token;
    }

    
    if (!this.shop_order_id) {
      this.error = 'invalid_shop_order_id';
      return;
    }

    if (!this.token) {
      this.error = 'invalid_token';
      return;
    }

    this.order = await this.get_order(this.token, this.shop_order_id);

    if (this.personal_details) {
      this.local_personal_details = { ...this.personal_details };
    }
    else {
      this.local_personal_details = this.order.member;
    }

    this.cancel_in_seconds = this.order.cancel_in_seconds;

    setInterval(()=>{
      
      this.cancel_in_seconds = this.cancel_in_seconds - 1;
    }, 1000);


    this.start_polling();

  },
  watch: {
    payment_method: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.payment_method) {
          this.selected_payment_method = this.payment_method;
        }
      },
      immediate: true,
    }
  },
  beforeDestroy() {
    this.stop_poll_shop_order();
  },
  methods: {
    invoice_done(order) {
      this.$router.push(`/invoice-done/${order.token}/${order.shop_order_id}`)
    },
    payment_done(order) {
      // when override from invoice payment to show that the payment is done

      if (order && order.redirect_url) {
        location.href = order.redirect_url;
      }
    
    },

    order_updated(order) {
      this.order = { ...order };
    },

    async get_order(token, shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/token/${token}/${shop_order_id}`);

        if (res.status === 200) {

          if (res.data.error) {
            this.error = res.data.error;
          }

          return res.data;
        }
      }
      catch (err) {
        console.error('get_order error', err);
      }

      throw `unable to get order`;
    },

    scroll_to_smooth_classname(classname) {

      this.$nextTick(()=>{
        
        document.getElementById('scroll-to-element').scrollIntoView({
          block: 'start',
          behavior: "smooth", 
        });
      });
    },

    payment_method_selected(method) {
      switch (method) {
        case 'SWISH': {
          this.selected_payment_method = 'SWISH';
          break;
        }
        case 'STRIPE': {
          this.selected_payment_method = 'STRIPE';
          break;
        }
        case 'AUTOGIRO': {
          this.selected_payment_method = 'AUTOGIRO';
          break;
        }
        case 'INVOICE': {
          this.selected_payment_method = 'INVOICE';
          break;
        }
      }

      console.log('payment method selected', this.selected_payment_method);

      this.scroll_to_smooth_classname('payment-component-section');
    },

    start_polling_from_child_component(show_payment_status) {
      this.show_payment_status = show_payment_status;
      this.start_polling();
    },

    start_polling() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.interval_id = setInterval(()=>{
        this.poll_shop_order();
      }, 5000);
    },

    async poll_shop_order() {
      try {
        const item = await this.get_order(this.order.token, this.order.shop_order_id);
        
        if (item) {
          this.order = { ...item };

          switch (this.order.status) {
            case 'DONE':
            case 'PAID_IN_FULL': {

              this.show_payment_status = true;

              if (this.order.type === 'REGISTER') {
                
                const url = getKeyValue('post_register_url');
                setKeyValue('post_register_url', null);

                if (url) {
                  setTimeout(()=>{
                    location.href = url;
                  }, 2000);
                }
              }
              else if (this.order.type === 'RENEW') {
                
                const url = getKeyValue('post_renew_url');
                setKeyValue('post_renew_url', null);

                if (url) {
                  setTimeout(()=>{
                    location.href = url;
                  }, 2000);
                }
              }
              else if (this.order.type === 'TICKET') {
                const url = getKeyValue('url_after_event_signup');
                setKeyValue('url_after_event_signup', null);

                if (url) {
                  setTimeout(()=>{
                    location.href = url;
                  }, 2000);
                }
              }

              break;
            }
            case 'DELETED':
            case 'FAILED': 
            case 'CANCELLED': {
              this.show_payment_status = true;

              this.stop_poll_shop_order();
              window.scrollTo(0, 0);
              break;        
            }
          }
        }
      }
      catch (err) {
        console.error('poll_shop_order error', err);
      }
    },

    stop_poll_shop_order() {
      if (this.interval_id) {
        clearInterval(this.interval_id);
      }
    },

  }
};
</script>


<style lang="css" scoped>
.amount {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  
  text-align: center;
}

.info {
  color: #1a1a1d;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}

</style>